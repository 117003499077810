const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern', 'wheel_backspace'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim', 'tire_height'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/shop-by-brand';

globalThis.Convermax.closeSideBar = () =>
  window.document.querySelector('.menu-mobile .main-nav .back-prev-menu .m-close').click();

function getFitmentSearchTitle() {
  const {
    location,
    ShopifyAnalytics: {
      meta: {
        page: { pageType },
      },
    },
  } = window;

  return location.pathname === categorySelectionPageUrl
    ? 'Collections'
    : location.pathname === brandSelectionPageUrl
      ? 'Brands'
      : pageType === 'collection'
        ? window.Convermax.collectionTitle
        : 'Parts';
}

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    searchResultsImageWidth: 240,
    searchResultsImageHeight: 240,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: true,
    getFitmentSearchTitle,
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm_category-page',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'collection_sort': 'Featured',
        'relevance': 'Best Match',
        'title': 'Name: A-Z',
        'title:desc': 'Name: Z-A',
        'price': 'Price: low to high',
        'price:desc': 'Price: high to low',
        'published_at': 'Oldest to newest',
        'published_at:desc': 'Newest to oldest',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: { selector: '#collections-listing', class: 'container' },
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brand-selection-page',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'SearchBox',
      location: { replace: '.header-middle form#search' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.mobile-layout-bar .search-button',
        class: 'search-button searchbox',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: {
        replace: '#cm_DesktopGarage',
        wrapper: 'li',
        class: 'cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        replace: '#cm_MobileGarage',
        wrapper: 'li',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { replace: '#cm_vehicle-widget__home' },
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      location: { insertBefore: '#product-info .product-policy-wrapper' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
