export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-1',
  BUTTON_SECONDARY_CLASS: 'btn btn-2',
  GARAGE_TITLE: 'Garage',
  GARAGE_SIZE: '<span class="garage-size" key="garage-size">{{size}}</span>',
  PRODUCT_REPEATER_CLASS: 'cata-product cp-grid',
  ADD_VEHICLE: 'Add a Vehicle',

  SEARCH_BOX_PLACEHOLDER: 'Search',
  SEARCH_BOX_INPUT_CLASS: 'form-control',
};
