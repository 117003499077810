//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-692:_6184,_6016,_568,_7852,_5968,_4832,_6356,_7396;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-692')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-692', "_6184,_6016,_568,_7852,_5968,_4832,_6356,_7396");
        }
      }catch (ex) {
        console.error(ex);
      }